<template>
  <div class="custoemrLoseWeightShape content_body">
    <div class="ConversionRepurchaseRate content_body">
      <div class="nav_header" style="padding:0px">
        <el-form :inline="true" size="small" :model="searchForm" @submit.native.prevent>
          <el-form-item label="门店">
            <el-select v-model="searchForm.EntityID" clearable filterable placeholder="请选择职务" @change="onHandleSearch">
              <el-option v-for="item in entity_list" :label="item.EntityName" :value="item.ID" :key="item.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户">
            <el-input size="small" v-model="searchForm.CustomerName" placeholder="请输入客户姓名、编号、手机号搜索" clearable  @keyup.enter.native="onHandleSearch"></el-input>
          </el-form-item>
          <el-form-item label="所属顾问">
            <el-input size="small" v-model="searchForm.EmployeeName" placeholder="请输入所属顾问名称搜索" clearable @keyup.enter.native="onHandleSearch"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-prevent-click @click="onHandleSearch">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-prevent-click @click="downloadExcel" :loading="downloadExcelLoading" v-if="isExport">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table size="small" :data="tableData" v-loading="tableLoading">
        <el-table-column prop="CustomerName" label="顾客姓名/编号">
          <template slot-scope="scope">
              {{ scope.row.CustomerName }} 
              <span v-if="scope.row.Code"> / {{ scope.row.Code }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="PhoneNumber" label="顾客手机号"></el-table-column>
        <el-table-column prop="EntityName" label="归属门店"></el-table-column>
        <el-table-column prop="EmployeeName" label="所属顾问"></el-table-column>
        <el-table-column prop="FirstTreatBillDate" label="首次购买减肥时间"></el-table-column>
        <el-table-column prop="FirstShapeTreatBillDate" label="首次购买塑形时间"></el-table-column>
        <el-table-column prop="DaysBetween" label="间隔天数"></el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handlePageChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Customer/custoemrLoseWeightShape.js";
import permission from "@/components/js/permission.js";
export default {
  name: "custoemrLoseWeightShape",

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isExport = permission.permission(to.meta.Permission, "Report-Customer-CustomerLoseWeightShape-Export");
    });
  },
  props: {},
  /** 监听数据变化   */
  watch: {},
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      downloadExcelLoading: false,
      isExport: false,
      tableLoading: false,
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      searchForm: {
        CustomerName: "", //顾客姓名/编号/手机号查询
        EntityID: null, //门店查询
        EmployeeName: "", //所属顾问名称搜索
      },
      tableData: [],
      entity_list: [],
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**    */
    handlePageChange(page) {
      let that = this;
      that.paginations.page = page;
      that.customerLoseWeight_list();
    },
    /**    */
    onHandleSearch() {
      let that = this;
      that.paginations.page = 1;
      that.customerLoseWeight_list();
    },
    /**    */
    customerLoseWeight_list() {
      let that = this;
      let params = {
        CustomerName: that.searchForm.CustomerName, //顾客姓名/编号/手机号查询
        EntityID: that.searchForm.EntityID, //门店查询
        EmployeeName: that.searchForm.EmployeeName, //所属顾问名称搜索
        PageNum: that.paginations.page, //页码
      };
      that.tableLoading = true;
      API.customerLoseWeight_list(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableLoading = false;
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.tableLoading = false;
            that.$message.error(res.Message);
          }
        })
        .catch((fail) => {
          that.tableLoading = false;
          that.$message.error(fail);
        });
    },
    /**    */
    reportEntity_storeList() {
      let that = this;
      let params = {};
      API.reportEntity_storeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entity_list = res.Data;
          } else {
            that.$message.error(res.Message);
          }
        })
        .catch((fail) => {
          that.$message.error(fail);
        });
    },

    /** 数据导出 */
    downloadExcel() {
      var that = this;
      let params = {
        CustomerName: that.searchForm.CustomerName, //顾客姓名/编号/手机号查询
        EntityID: that.searchForm.EntityID, //门店查询
        EmployeeName: that.searchForm.EmployeeName, //所属顾问名称搜索
        PageNum: that.paginations.page, //页码
      };
      that.downloadExcelLoading = true;
      API.customerLoseWeight_excel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "减肥会员转塑形查询表.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadExcelLoading = false;
        });
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    let that = this;
    that.isExport = permission.permission(this.$route.meta.Permission, "Report-Customer-CustomerLoseWeightShape-Export");
    that.reportEntity_storeList();
    that.customerLoseWeight_list();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.custoemrLoseWeightShape {
}
</style>
